<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/icomoon/icomoon.css";
// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/

// Demo skins (demo1 only)
@import "assets/sass/global/integration/frameworks/vue/skins";

.btn-starkoms-primary{
  background: linear-gradient(90deg, #BF1324 0%, #F95812 100%) !important;
  color: #FFFFFF !important;
}
#datepicker-starkoms .v-picker__title{
  background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
  
}
#datepicker-starkoms .accent
{
    background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
}
#datepicker-starkoms .accent--text{
    color: #e55d43 !important
}
.beamerButton .kt-header__topbar-icon {
    background: #fff;
    border: 2px solid #f45314;
}
.beamerButton .kt-header__topbar-icon svg.kt-svg-icon g {
    fill: #f45314;
    color: #f45314;
}

.beamerButton .kt-header__topbar-icon svg.kt-svg-icon g rect {
    opacity: 1;
}
.kt-header-base-light .kt-header .kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon{
    background: #c21623;
}
</style>
<style scoped lang="scss">
 ::v-deep .v-application--wrap {
    min-height: fit-content;
  }
</style>
<script>
//import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module";
import { mapState } from 'vuex'
export default {
  name: "Starkoms",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    //console.log(this.$store.state.auth);
  },
  computed: mapState({
    user(state){
      return state.passport.user
    },
  }),
  created() {
    this.$intercom.shutdown();
    this.$intercom.once('ready', this.bootIntercom);
    
    
    // console.log(Getbeamer);
    // Getbeamer.created();
  },
  methods: {
    bootIntercom() {
      this.$intercom.boot({
        user_id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        Store: this.user.default_store_name,
        company: {
          name: this.user.default_store_name,
          company_id: this.user.default_store_id
        }
      });
    },
    loadBeamer(){
      console.log('loadBeamer');
      const options = Object.assign({}, {
        product_id: 'ZKWfMosp42109',
        selector : ".beamerButton",
        display : "right",
        // button: false,
        bottom: 0,
        right: 0,
        button_position: 'bottom-right',
        language: 'ES',
        // filter: 'admin',
        lazy: false,
        alert : true,
        // counter: true,
        // callback : your_callback_function,
        //---------------Visitor Information---------------
        user_firstname : this.user.name,
        user_lastname : this.user.name,
        user_email : this.user.email,
      });
      console.log(options);
      window["beamer_config"] = (options);
      console.log('loaded');
      var scripttag = document.createElement("script");
        scripttag["async"] = true;
        scripttag["src"] = `https://app.getbeamer.com/js/beamer-embed.js`;
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(scripttag, scr);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from){
        console.log('Se refresco la URL: ', to.name);
        if(to.name == 'login'){
          this.$intercom.shutdown();
        }else{
          this.loadBeamer();
          this.$intercom.update({
            user_id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            Store: this.user.default_store_name,
            company: {
              name: this.user.default_store_name,
              company_id: this.user.default_store_id
            }
          });
        }
        /*
        if(to.meta.title){
          document.title = (to.meta.title+' | '+this.user.default_store_name);
        }else{
          document.title = 'OMS';
        }
        */
      }
    },
  }
};
</script>

